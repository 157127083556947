<template>
  <div id="app">
      <b-navbar type="light" variant="vnav">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-navbar-nav class="d-none d-lg-flex ml-auto">
            <b-nav-item href="https://www.scarborosubaru.ca/" class="mx-3">Scarboro Subaru</b-nav-item>
            <b-nav-item href="https://www.markhamsubaru.com/" class="mx-3">Markham Subaru</b-nav-item>
        </b-navbar-nav>
        <b-navbar-brand class="mx-auto mx-lg-2"><b-img :src="Logo" style="max-height: 80px"></b-img></b-navbar-brand>
        <b-navbar-nav class="d-none d-lg-flex mr-auto">
            <b-nav-item href="https://www.scarborokia.ca/" class="mx-3">Scarboro Kia</b-nav-item>
            <b-nav-item href="https://carmenandfrankcollision.ca/" class="mx-3">Carmen & Frank Collision</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
      <b-container>
      <b-carousel
          controls
          indicators
          img-width="1024"
          img-height="575"
          style="text-shadow: 1px 1px 2px #333; box-shadow: 3px 7px 4px rgba(0, 0, 0, 0.5);"
      >
        <!-- Text slides with image -->
        <b-carousel-slide :img-src="SSslide" style="">
          <span class="d-inline-block h2" @click="link('https://www.scarborosubaru.ca')" style="background-color: rgba(30,30,30,0.60); padding: 3px 5px 5px 5px; border-radius: 10px;  display: inline-block; margin-bottom: 10px; cursor: pointer;">
            Scarboro Subaru
          </span>
          <p class="d-none d-md-block" style="background-color: rgba(30,30,30,0.60); padding: 3px 5px 5px 5px; border-radius: 10px;">
            In The Original Location of Carmen & Frank's Garage - Scarboro Subaru has been serving the Scarborough community like family since 1986.
          </p>
        </b-carousel-slide>
        <b-carousel-slide :img-src="MSslide">
          <span class="d-inline-block h2" @click="link('https://www.markhamsubaru.com/')" style="background-color: rgba(30,30,30,0.60); padding: 3px 5px 5px 5px; border-radius: 10px;  display: inline-block; margin-bottom: 10px;  cursor: pointer;">
            Markham Subaru
          </span>
          <p class="d-none d-md-block" style="background-color: rgba(30,30,30,0.60); padding: 3px 5px 5px 5px; border-radius: 10px;">
            Building on the success of our Scarborough dealerships - We expanded with our second Subaru dealership bring our friendly and professional service to the community of Markham.
          </p>
        </b-carousel-slide>
        <b-carousel-slide :img-src="SKslide">
          <span class="d-inline-block h2" @click="link('https://www.scarborokia.ca')" style="background-color: rgba(30,30,30,0.60); padding: 3px 5px 5px 5px; border-radius: 10px;  display: inline-block; margin-bottom: 10px;  cursor: pointer;">
            Scarboro Kia
          </span>
          <p class="d-none d-md-block" style="background-color: rgba(30,30,30,0.60); padding: 3px 5px 5px 5px; border-radius: 10px;">
            As Toronto's Original Kia Dealership - We bring you our trusted, friendly and professional services and pared with one of the most exciting and inspired brands on the market.
          </p>
        </b-carousel-slide>
        <b-carousel-slide :img-src="CFslide">
          <span class="d-inline-block h2" @click="link('https://carmenandfrankcollision.ca')" style="background-color: rgba(30,30,30,0.60); padding: 3px 5px 5px 5px; border-radius: 10px;  display: inline-block; margin-bottom: 10px;  cursor: pointer;">
            Carmen & Frank Collision Centre
          </span>
          <p class="d-none d-md-block" style="background-color: rgba(30,30,30,0.60); padding: 3px 5px 5px 5px; border-radius: 10px;">
            Guaranteed Professional Collision Repair from The Family You Have Trusted for Decades - the best choice to repair & rejuvenate your vehicle, whether you have been in a major or minor collision, had your vehicle damaged in a parking lot, or want to remove scratches, dents, ding, and chips.
          </p>
        </b-carousel-slide>
      </b-carousel>
        <b-card no-body bg-variant="vnav" text-variant="dark" class="mt-3 rounded-0" style="box-shadow: 3px 7px 4px rgba(0, 0, 0, 0.5);">

          <b-card-body>
            <div class="w-100 text-center mb-2"><span class="h3">Shop Our Products & Services</span></div>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-5">
              <div class="col px-1">
                <b-dropdown variant="black" block text="New Vehicles" class="m-2" menu-class="cta-btns">
                  <b-dropdown-item target="_blank" href="https://www.scarborosubaru.ca/new-vehicles/subaru.html">Scarboro Subaru</b-dropdown-item>
                  <b-dropdown-item target="_blank" href="https://www.markhamsubaru.com/new/">Markham Subaru</b-dropdown-item>
                  <b-dropdown-item target="_blank" href="https://www.scarborokia.ca/new-kia-scarborough-ontario">Scarboro Kia</b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="col px-1">
                <b-dropdown variant="black" block text="Pre-Owned Vehicles" class="m-2" menu-class="cta-btns">
                  <b-dropdown-item target="_blank" href="https://www.scarborosubaru.ca/used/">Scarboro Subaru</b-dropdown-item>
                  <b-dropdown-item target="_blank" href="https://www.markhamsubaru.com/used/">Markham Subaru</b-dropdown-item>
                  <b-dropdown-item target="_blank" href="https://www.scarborokia.ca/used-cars-scarborough-ontario">Scarboro Kia</b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="col px-1">
                <b-dropdown variant="black" block text="Schedule Service" class="m-2" menu-class="cta-btns">
                  <b-dropdown-item target="_blank" href="https://www.scarborosubaru.ca/service/">Scarboro Subaru</b-dropdown-item>
                  <b-dropdown-item target="_blank" href="https://www.markhamsubaru.com/schedule-a-service/">Markham Subaru</b-dropdown-item>
                  <b-dropdown-item target="_blank" href="https://www.scarborokia.ca/service-appointment-auto-mechanic">Scarboro Kia</b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="col px-1">
                <b-dropdown variant="black" block text="Order Parts" class="m-2" menu-class="cta-btns">
                  <b-dropdown-item target="_blank" href="https://www.scarborosubaru.ca/parts-quote/">Scarboro Subaru</b-dropdown-item>
                  <b-dropdown-item target="_blank" href="https://www.markhamsubaru.com/parts-quote/">Markham Subaru</b-dropdown-item>
                  <b-dropdown-item target="_blank" href="https://www.scarborokia.ca/scarborough-ontario-tires">Scarboro Kia</b-dropdown-item>
                  <b-dropdown-item target="_blank" href="https://shop.vigliatoreautogroup.ca/">Accessory Outlet Store</b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="col px-1">
                <b-dropdown variant="black" block text="Collision Care" class="m-2" menu-class="cta-btns">
                  <b-dropdown-item target="_blank" href="https://carmenandfrankcollision.ca/home">Carmen & Frank Collision Centre</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </b-card-body>
        </b-card>

      </b-container>
  </div>
</template>

<script>

import * as logo from './assets/logo.png'
import * as SSslide from './assets/21CRSS_LP_ES_front_motion_city_CGK.jpg'
import * as MSslide from './assets/21FOR_SP_ES_Country_Road_DBP_SOA.jpg'
import * as SKslide from './assets/21KK5-017-7.jpg'
import * as CFslide from './assets/AdobeStock_244191246.jpeg'

export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      Logo: logo,
      SSslide: SSslide,
      MSslide: MSslide,
      SKslide: SKslide,
      CFslide: CFslide
    }
  },
  methods: {
    link: function (url) {
      window.location.href = url
    }
  }
}
</script>
